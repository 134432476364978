
import { Component } from 'vue-property-decorator';
import { RouteConfig } from 'vue-router';
import SubModule from '@/components/SubModule.vue';
import { IMenuItem } from '@/components/Menu.vue';
import routes from './routes';
import store, { Getters } from './store';

@Component
export default class SystemConfiguration extends SubModule
{
  private get activeItem(): typeof store[Getters.ACTIVE_ITEM]
  {
    return store[Getters.ACTIVE_ITEM];
  }

  protected _getRoutes(): RouteConfig[]
  {
    return routes;
  }

  protected _getDynamicMenuItems(): IMenuItem[]
  {
    const activeItem = this.activeItem;
    if (activeItem)
    {
      if (activeItem.$isCreated())
      {
        return [
          {
            title: this.$t(`keys.${activeItem.key}`).toString(),
            icon: `$vuetify.icons.edit`,
            route: `/systemconfiguration/${activeItem.key}`,
          },
        ];
      }
    }
    return [];
  }
}
